<template>
  <div class="container-fluid">
    <h3 class="text-center">Configurações</h3>
    <div class="row text-white px-3">
      <div class="col-12 btn btn-outline-info my-2">
        <router-link class="dropdown-item" to="/admin/configuracoes/parametros">Parâmetros de sistema</router-link>
      </div>
      <div class="col-12 btn btn-outline-info my-2">
        <router-link class="dropdown-item" to="/admin/configuracoes/deficiencias">Deficiências</router-link>
      </div>
      <div class="col-12 btn btn-outline-info my-2">
        <router-link class="dropdown-item" to="/admin/configuracoes/cargos">Cargos</router-link>
      </div>
      <div class="col-12 btn btn-outline-info my-2">
        <router-link class="dropdown-item" to="/admin/treinamentos/locais">Locais de Treinamento</router-link>
      </div>
      <div class="col-12 btn btn-outline-info my-2">
        <router-link class="dropdown-item" to="/admin/treinamentos/normas">Normas</router-link>
      </div>
      <div class="col-12 btn btn-outline-info my-2">
        <router-link class="dropdown-item" to="/admin/configuracoes/instrutores">Instrutores</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>